'use strict';

angular.module('windmanagerApp')
  .directive('listFinding', function() {
    return {
      templateUrl: 'components/directives/finding/listFinding/listFinding.html',
      restrict: 'EA',
      scope: {
        turbine: '=',
        blade: '=',
        components: '=',
        daterange: '='
      },
      controller: 'ListFindingCtrl',
      controllerAs: 'listFinding',
      link: function(scope, element, attrs) {
      }
    };
  });
